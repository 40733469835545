import AddIcon from '@mui/icons-material/Add'
import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { Button, Col, Row, Text } from '@/components/atoms'
import { TitleWithDescription } from '@/components/molecules'
import { BREAKPOINTS } from '@/constants'
import {
  CheckInModal,
  CheckOutModal,
  DeleteQueueItemModal,
  QueueCard
} from '@/features/gate'
import { LaneDirection } from '@/features/gate/enums'
import { IGateQueueItem, IQueueModalType } from '@/features/gate/types'
import { useModal } from '@/hooks'
import { Color } from '@/styles/palette'
import { FontWeight, TextTypes } from '@/types/enums/ui'

import styles from './GateQueue.module.scss'

interface IProps {
  type: LaneDirection
  items: IGateQueueItem[]
  onDelete: (type: LaneDirection, id: string) => void
  onSwap: (type: LaneDirection, id: string) => void
}

const GateQueue: FC<IProps> = (props) => {
  const { type, items, onDelete, onSwap } = props

  const [selectedItem, setSelectedItem] = useState<IGateQueueItem | undefined>()

  const { visible, openModal, closeModal } = useModal<IQueueModalType>()
  const { width } = useWindowSize()

  const title = type === LaneDirection.Arriving ? 'Check-In' : 'Check-Out'

  const emptyQueueDescription = `Power units ready to be checked-${
    type === LaneDirection.Arriving ? 'in' : 'out'
  } will appear here.`

  const tabsMode = width <= BREAKPOINTS.LG

  const openModalCustom = (
    item: IGateQueueItem | undefined,
    modalType: IQueueModalType
  ) => {
    setSelectedItem(item)
    openModal(modalType)
  }

  const closeModalCustom = () => {
    closeModal()
    setSelectedItem(undefined)
  }

  const onManualCheckIn = () => {
    openModalCustom(undefined, type)
  }

  const handleDelete = () => {
    if (!selectedItem) return

    onDelete(type, selectedItem.id)
    closeModal()
  }

  return (
    <Col
      items="stretch"
      className={clsx(styles.queueWrapper, tabsMode && styles.small)}
    >
      {visible === LaneDirection.Arriving && (
        <CheckInModal closeModal={closeModalCustom} item={selectedItem} />
      )}

      {visible === LaneDirection.Departing && (
        <CheckOutModal closeModal={closeModalCustom} item={selectedItem} />
      )}

      {visible === 'delete' && (
        <DeleteQueueItemModal
          closeModal={closeModalCustom}
          onDelete={handleDelete}
        />
      )}

      {!tabsMode && (
        <Row
          items="center"
          gap={20}
          justify="between"
          className={styles.queueHeader}
        >
          <Row items="center" gap={10}>
            <Text
              type={TextTypes.TEXT_MD}
              color={Color.gray700}
              weight={FontWeight.SEMIBOLD}
            >
              {title}
            </Text>

            {!!items.length && (
              <div className={styles.queueLength}>
                <Text
                  type={TextTypes.TEXT_MD}
                  weight={FontWeight.SEMIBOLD}
                  color={Color.gray700}
                >
                  {items.length}
                </Text>
              </div>
            )}
          </Row>

          <Button type="outlined" onClick={onManualCheckIn}>
            <AddIcon />
          </Button>
        </Row>
      )}

      {items.length ? (
        <Col gap={8} items="stretch" className={styles.queueItems}>
          {items.map((item) => (
            <QueueCard
              key={item.id}
              type={type}
              item={item}
              onSwap={onSwap}
              openModal={openModalCustom}
            />
          ))}
        </Col>
      ) : (
        <TitleWithDescription
          title="There is nothing in the queue."
          description={emptyQueueDescription}
        />
      )}
    </Col>
  )
}

export default GateQueue
