import IosShareIcon from '@mui/icons-material/IosShare'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button, Col, HeaderActionPortal } from '@/components/atoms'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { DriverDetailsModal } from '@/features/gate'
import { IDriverItem } from '@/features/gate/types'
import {
  driversColumns,
  driversFilters,
  driversMockData,
  prepareDriversUrlFilters
} from '@/features/gate/utils'
import { useModal } from '@/hooks'
import { TableActions } from '@/types/enums/table'

const GateDriversTemplate = () => {
  const [searchParams] = useSearchParams()

  const { visible, openModal, closeModal } = useModal(false)

  const [selectedItem, setSelectedItem] = useState<IDriverItem | undefined>()

  const appointmentTypeFilter = searchParams.get('appointmentType')
  const mismatch = searchParams.get('mismatch')
  const dwell = searchParams.get('puDwell')

  const searchFields: (keyof IDriverItem)[] = [
    'appointmentType',
    'puName',
    'operatingCarrier',
    'account',
    'puLPN',
    'puLPNState',
    'usDOT',
    'puClass',
    'puFuelType',
    'driverName',
    'driverLicenseNum',
    'driverPhoneNum',
    'assetName'
  ]

  const defaultFilters = useMemo(
    () => prepareDriversUrlFilters(appointmentTypeFilter, mismatch, dwell),
    [appointmentTypeFilter, mismatch, dwell]
  )

  const handleAction = (action: TableActions, row: any) => {
    switch (action) {
      case TableActions.RowClick: {
        openModal()
        setSelectedItem(row)
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      {visible && (
        <DriverDetailsModal
          item={selectedItem as IDriverItem}
          closeModal={closeModal}
        />
      )}

      <WithHeaderTemplate title="Drivers On Site">
        <Col items="stretch" className="tw-h-full">
          <HeaderActionPortal>
            <Button type="primary" startIcon={<IosShareIcon />}>
              Export
            </Button>
          </HeaderActionPortal>

          <div className="tw-flex-1 tw-flex">
            <Table
              clickable
              name="drivers"
              idAccessor="id"
              rows={driversMockData}
              columns={driversColumns}
              handleAction={handleAction}
              filters={driversFilters}
              searchFields={searchFields}
              defaultFilters={defaultFilters}
              searchPlaceholder="Search Visitor"
            />
          </div>
        </Col>
      </WithHeaderTemplate>
    </>
  )
}

export default GateDriversTemplate
