import IosShareIcon from '@mui/icons-material/IosShare'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { Button, Col, HeaderActionPortal } from '@/components/atoms'
import { Table } from '@/components/organisms'
import { WithHeaderTemplate } from '@/components/templates'
import { CargoAssetDetailsModal } from '@/features/yard'
import { ICargoAssetItem } from '@/features/yard/types'
import {
  cargoAssetMockData,
  cargoAssetsFilters,
  cargoAssetsTableShape,
  prepareCargoAssetUrlFilters
} from '@/features/yard/utils'
import { useModal } from '@/hooks'
import { TableActions } from '@/types/enums/table'

const CargoAssetTemplate = () => {
  const [searchParams] = useSearchParams()
  const { visible, openModal, closeModal } = useModal(false)

  const [selectedItem, setSelectedItem] = useState<any>()

  const assetCarrierFilter = searchParams.get('assetCarrier')
  const mismatch = searchParams.get('mismatch')
  const dwell = searchParams.get('assetDwell')

  const defaultFilters = useMemo(
    () => prepareCargoAssetUrlFilters(assetCarrierFilter, mismatch, dwell),
    [assetCarrierFilter, mismatch, dwell]
  )

  const searchFields: (keyof ICargoAssetItem)[] = [
    'appointmentType',
    'assetName',
    'chassisName',
    'assetLPN',
    'assetLPNState',
    'assetCarrier',
    'operatingCarrier',
    'assetType',
    'arrivingLoadStatus',
    'shipmentNumber',
    'account',
    'sealNumber'
  ]

  const handleAction = (action: TableActions, row: any) => {
    switch (action) {
      case TableActions.RowClick: {
        setSelectedItem(row)
        openModal()
        break
      }

      default: {
        break
      }
    }
  }

  return (
    <>
      {visible && (
        <CargoAssetDetailsModal item={selectedItem} closeModal={closeModal} />
      )}

      <WithHeaderTemplate title="Cargo Assets On Site">
        <Col items="stretch" className="tw-h-full">
          <HeaderActionPortal>
            <Button type="primary" startIcon={<IosShareIcon />}>
              Export
            </Button>
          </HeaderActionPortal>

          <div className="tw-flex-1 tw-flex">
            <Table
              clickable
              idAccessor="id"
              name="cargoAssets"
              rows={cargoAssetMockData}
              searchFields={searchFields}
              handleAction={handleAction}
              filters={cargoAssetsFilters}
              defaultFilters={defaultFilters}
              columns={cargoAssetsTableShape}
              searchPlaceholder="Search Cargo Asset"
            />
          </div>
        </Col>
      </WithHeaderTemplate>
    </>
  )
}

export default CargoAssetTemplate
