import { baseApi, withParams } from '@/api'

import endpoints from './endpoints'
import {
  FetchGatesRequest,
  FetchGatesResponse,
  FetchLanesRequest,
  FetchLanesResponse
} from '@/features/auth/api/types'

export const gateApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchGates: builder.query<FetchGatesResponse, FetchGatesRequest>({
      query: ({ org_id, site_id }) => {
        return withParams(endpoints.GET_GATES, { org_id, site_id })
      }
    }),

    fetchLanes: builder.query<FetchLanesResponse, FetchLanesRequest>({
      query: ({ org_id, site_id, gate_id }) => {
        return withParams(endpoints.GET_LANES, { org_id, site_id, gate_id })
      }
    })
  })
})

export const { useFetchGatesQuery, useFetchLanesQuery } = gateApi
