import * as Sentry from '@sentry/react'
import { useEffect, useMemo, useRef } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { useMobileNavigationContext } from '@/components/contexts'
import { Navigation } from '@/components/organisms'
import { ErrorTemplate } from '@/components/templates'
import { ROUTES, useNavigator } from '@/router'
import { useStore } from '@/store'
import { ErrorType } from '@/types/enums/ui'
import { INavigationSubItem } from '@/types/interfaces/ui'
import { navigationItems } from '@/utils/data/navigationItems'

import styles from './MainTemplate.module.scss'

const MainTemplate = () => {
  const location = useLocation()
  const navigate = useNavigator()

  const path = location.pathname

  const { selectedPortal } = useStore((store) => store.user)
  const { toggleMobileNavigation } = useMobileNavigationContext()

  const prevPortalRef = useRef<string | undefined>(selectedPortal?.id)

  const allowedLinks: string[] = useMemo(
    () =>
      selectedPortal?.id && selectedPortal?.permissions
        ? navigationItems.reduce((links: string[], navItem) => {
            const visible = navItem.visible(
              selectedPortal.type,
              selectedPortal.permissions
            )

            if (!visible) return links

            if (navItem.link) {
              links.push(navItem.link)
            }

            if (Array.isArray(navItem.items)) {
              const allowedSubItems: INavigationSubItem[] =
                navItem.items.filter((item) =>
                  item.visible(selectedPortal.permissions)
                )

              links.push(...allowedSubItems.map((item) => item.link))
            }

            return links
          }, [])
        : [],
    [selectedPortal]
  )

  useEffect(() => {
    toggleMobileNavigation(false)

    const isPortalChanged = prevPortalRef.current !== selectedPortal?.id

    // No need to auto redirect if user is on profile page and portal is not changed
    if (location.pathname === ROUTES.PROFILE && !isPortalChanged) return

    prevPortalRef.current = selectedPortal?.id

    if (!allowedLinks.includes(location.pathname)) {
      navigate.to(allowedLinks?.length ? allowedLinks[0] : '/')
    }
  }, [location.pathname, allowedLinks])

  return (
    <div className={styles.wrapper}>
      <Navigation />

      <Sentry.ErrorBoundary
        key={path}
        fallback={<ErrorTemplate type={ErrorType.Generic} />}
      >
        <Outlet />
      </Sentry.ErrorBoundary>
    </div>
  )
}

export default MainTemplate
