import { Table, TableBody, TableContainer } from '@mui/material'
import clsx from 'clsx'
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { useDebounce } from 'usehooks-ts'

import { Col, Footer, Loader } from '@/components/atoms'
import { TableContextProvider } from '@/components/contexts'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { TableActions } from '@/types/enums/table'
import { ISortQueryFormat } from '@/types/interfaces/api'
import {
  IFilterItem,
  IFilterValues,
  IGroupByItem,
  IHighOrderColumn,
  ITableColumn
} from '@/types/interfaces/table'
import { IPagination, Order } from '@/types/interfaces/ui'
import { filter, sort } from '@/utils/table'

import {
  NoResults,
  TableFilters,
  TableHeader,
  TablePagination,
  TableRow as CustomTableRow
} from './components'
import styles from './Table.module.scss'

interface IProps {
  name: string
  loading?: boolean
  columns: (groupBy: string | undefined) => ITableColumn[]
  highOrderColumns?: (groupBy: string | undefined) => IHighOrderColumn[]
  rows: any[]
  idAccessor: string
  clickable?: boolean
  defaultFilters?: IFilterValues
  filters?: IFilterItem[]
  onSearch?: (value: string) => void
  perPage?: number
  currentPage?: number
  totalPages?: number
  totalItems?: number
  onPageChange?: (page: number) => void
  onSortChange?: (sort: ISortQueryFormat) => void
  enablePagination?: boolean
  searchPlaceholder?: string
  searchFields?: string[]
  groupByOptions?: IGroupByItem[]
  EmptyScreen?: ReactNode
  isRowHighlighted?: (row: any) => boolean
  isRowDisabled?: (row: any) => boolean
  handleAction?: (action: TableActions, row: any) => void
  groupingHelper?: (groupBy: string, rows: any[]) => any[]
}

const CustomTable: FC<IProps> = (props) => {
  const {
    name,
    columns,
    rows = [],
    idAccessor,
    onSearch,
    onPageChange,
    loading = false,
    currentPage,
    totalPages,
    totalItems,
    onSortChange,
    perPage = PAGINATION_ITEMS_PER_PAGE,
    clickable,
    filters,
    searchFields,
    groupByOptions,
    defaultFilters,
    handleAction,
    isRowHighlighted,
    isRowDisabled,
    groupingHelper,
    highOrderColumns,
    searchPlaceholder = 'Search',
    enablePagination = true,
    EmptyScreen
  } = props

  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [groupBy, setGroupBy] = useState<string | undefined>()
  const [filterValues, setFilterValues] = useState<IFilterValues>({})

  const [sortField, setSortField] = useState<string | undefined>()
  const [sortOrder, setSortOrder] = useState<Order>(undefined)

  const [page, setPage] = useState<number>(currentPage || 0)

  const pagination: IPagination = {
    perPage,
    currentPage: page,
    totalItems: totalItems || rows.length,
    totalPages: totalPages || Math.ceil(rows.length / perPage)
  }

  const debouncedSearch = useDebounce(searchValue, 500)

  const withSearch = !!searchFields || !!onSearch
  const withFilters = withSearch || !!groupByOptions || !!filters

  const memoCols: ITableColumn[] = useMemo(() => columns(groupBy), [groupBy])
  const memoHighOrderCols: IHighOrderColumn[] | undefined = useMemo(
    () => highOrderColumns?.(groupBy),
    [groupBy]
  )

  const memoRows: any[] = useMemo(() => {
    const filtered = filters?.length
      ? filter(filters, filterValues, rows)
      : rows

    const grouped = groupBy
      ? groupingHelper?.(groupBy, filtered) || []
      : filtered

    // For client side sorting
    if (sortField && sortOrder && !onSortChange) {
      return sort({
        rows: grouped,
        columns: memoCols,
        sortOrder,
        sortField,
        groupBy
      })
    }

    // For client side pagination
    if (grouped.length && enablePagination && !onPageChange) {
      const start = page * pagination.perPage
      const end = start + pagination.perPage

      return grouped.slice(start, end)
    }

    return grouped
  }, [rows, groupBy, searchValue, filterValues, sortField, sortOrder, page])

  const withItems = !!memoRows.length
  const noItems = !memoRows.length && !rows.length
  const noItemsAndNoEmptyScreen = noItems && !EmptyScreen
  const noItemsAfterFilterApplied = !memoRows.length && rows.length

  const providerValue = useMemo(
    () => ({
      searchValue,
      filterValues,
      groupBy,
      searchPlaceholder,

      filters,
      groupByOptions,

      isRowHighlighted,
      isRowDisabled,
      setFilterValues,
      handleAction,
      onSearchChange: setSearchValue,
      onGroupByChange: setGroupBy
    }),
    [
      searchValue,
      filterValues,
      groupBy,
      filters,
      groupByOptions,
      handleAction,
      isRowHighlighted,
      isRowDisabled,
      searchPlaceholder
    ]
  )

  const clearFilters = () => {
    setSearchValue('')
    setFilterValues({})
  }

  const handleSort = (property: string) => {
    if (sortField !== property) {
      setSortField(property)
      setSortOrder('asc')

      onSortChange?.(`${property}:asc`)
    } else if (sortOrder === 'asc') {
      setSortOrder('desc')

      onSortChange?.(`${property}:desc`)
    } else {
      setSortField(undefined)
      setSortOrder(undefined)

      onSortChange?.(undefined)
    }
  }

  const handlePageChange = (value: number) => {
    if (onPageChange) {
      onPageChange(value)
    }

    setPage(value)
  }

  useEffect(() => {
    if (defaultFilters) {
      setFilterValues(defaultFilters)
    }
  }, [defaultFilters])

  useEffect(() => {
    // Trigger search when search value is empty or min 3 characters
    if (!debouncedSearch || debouncedSearch?.length >= 3) {
      onSearch?.(debouncedSearch || '')
      handlePageChange(0)
    }
  }, [debouncedSearch])

  return (
    <TableContextProvider value={providerValue}>
      <Col items="stretch" className="tw-self-stretch tw-w-full">
        {withFilters && <TableFilters withSearch={withSearch} />}

        <div
          className={clsx(
            styles.tableWrapper,
            withItems && !loading && styles.withItems
          )}
        >
          <TableContainer className={styles.tableContainer}>
            <Table
              size="medium"
              aria-labelledby={name}
              classes={{
                root: clsx(
                  styles.table,
                  withFilters && styles.borderTop,
                  clickable && styles.clickable
                )
              }}
            >
              <TableHeader
                sortOrder={sortOrder}
                sortField={sortField}
                cols={memoCols}
                highOrderCols={memoHighOrderCols}
                handleSort={handleSort}
              />

              {withItems && !loading && (
                <TableBody>
                  {memoRows.map((row) => (
                    <CustomTableRow
                      key={row[idAccessor]}
                      row={row}
                      columns={memoCols}
                      idAccessor={idAccessor}
                      clickable={clickable}
                    />
                  ))}
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {enablePagination && withItems && !loading && (
            <Footer>
              <TablePagination
                pagination={pagination}
                onPageChange={handlePageChange}
              />
            </Footer>
          )}
        </div>

        {noItems && !loading && EmptyScreen}

        {(noItemsAfterFilterApplied || noItemsAndNoEmptyScreen) && !loading && (
          <NoResults clearFilters={clearFilters} />
        )}

        {loading && (
          <Col items="center" justify="center" gap={8} className="tw-flex-1">
            <Loader />
          </Col>
        )}
      </Col>
    </TableContextProvider>
  )
}

export default CustomTable
