import AddIcon from '@mui/icons-material/Add'
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import React, { CSSProperties, FC, useEffect, useMemo, useState } from 'react'
import { useWindowSize } from 'usehooks-ts'

import { Button } from '@/components/atoms'
import { Tabs } from '@/components/molecules'
import { BREAKPOINTS } from '@/constants'
import { GateQueue } from '@/features/gate'
import { LaneDirection } from '@/features/gate/enums'
import { IGateQueueItem, Lane } from '@/features/gate/types'
import { checkInItems, checkOutItems } from '@/features/gate/utils'
import { ITab } from '@/types/interfaces/ui'

import styles from './Queues.module.scss'

interface IProps {
  openModal: (type: LaneDirection) => void
  lanes: Lane[]
}

const Queues: FC<IProps> = (props) => {
  const { openModal, lanes } = props

  const { width } = useWindowSize()
  const isSmallView = width <= BREAKPOINTS.LG

  const [activeTab, setActiveTab] = useState<string | undefined>()

  const onDelete = (type: LaneDirection, id: string) => {
    // TODO: Logic here once endpoint is ready
    console.log(type, id)
  }

  const onSwap = (type: LaneDirection, id: string) => {
    // TODO: Logic here once endpoint is ready
    console.log(type, id)
  }

  const onManuallyClick = () => {
    if (!activeTab) return

    const selectedLane = lanes.find((lane) => lane.id === activeTab)

    openModal(selectedLane?.direction as LaneDirection)
  }

  const queuesList = useMemo(
    () =>
      lanes.map((lane) => {
        const { id, direction } = lane

        const items: IGateQueueItem[] =
          direction === LaneDirection.Arriving ? checkInItems : checkOutItems

        return {
          id,
          direction,
          items: items.length,
          Component: (
            <GateQueue
              onDelete={onDelete}
              onSwap={onSwap}
              items={items}
              type={direction}
            />
          )
        }
      }),
    [lanes]
  )

  const tabs: ITab[] = useMemo(
    () =>
      queuesList.map((queue) => ({
        id: queue.id,
        title:
          queue.direction === LaneDirection.Arriving ? 'Check-In' : 'Check-Out',
        icon:
          queue.direction === LaneDirection.Arriving ? (
            <LoginOutlinedIcon />
          ) : (
            <LogoutOutlinedIcon />
          ),
        items: queue.items,
        Component: queue.Component
      })),
    [queuesList]
  )

  useEffect(() => {
    setActiveTab(queuesList[0]?.id)
  }, [queuesList])

  return isSmallView ? (
    <div className="tw-flex-1 tw-flex">
      <Tabs
        countAsBadge
        active={activeTab}
        setActive={setActiveTab}
        tabs={tabs}
        actions={
          <Button type="outlined" onClick={onManuallyClick}>
            <AddIcon />
          </Button>
        }
      />
    </div>
  ) : (
    <div
      className={styles.queues}
      // We need this to dynamically generate the grid columns
      style={{ '--count': queuesList.length || 1 } as CSSProperties}
    >
      {queuesList.map((queue) => queue.Component)}
    </div>
  )
}

export default Queues
